:root {
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color-scheme: light dark;
	color: #444;
	background: #fafafa;
}

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}

#app > main {
	margin: 20px auto;
	min-height: 100vh;
	max-width: 1280px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/* align-items: center; */
	gap: 4vw;
	background-color: #fafafa;
}

#app > main > section {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 20vh;
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 2em;
	overflow-y: auto;
	padding-bottom: 10vh;
}

#app > main > section > button {
	font-size: larger;
	position: fixed;
	bottom: 10vh;
	bottom: 10vh;
    padding: 1em 2em;
    background-color: red;
    outline: none;
    border: none;
    border-radius: 20px;
    left: 50%;
    transform: translate(-50%, 10px);
}
