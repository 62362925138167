.lesson_card {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px;
	gap: 12px;
}
.lesson_card:hover {
	background-color: tomato;
}
.lesson_card .lesson_icon {
	height: 50px;
}
.lesson_card a {
	font-size: larger;
	color: green;
	cursor: pointer;
	text-decoration: none;
}