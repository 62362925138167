.class_card {
	cursor: pointer;
	/* padding: 16px 0; */
	width: 30vw;
	border-radius: 4px;
	background-size: cover;
	background-position: center;
	position: relative;
	padding-top: 16.8vw;
	box-shadow:  0 0 5px 2px rgba(0, 0, 0, .3);
}
@media (max-width: 639px) {
	.class_card {
		width: 100vw;
		padding-top: 48vw;
	}
}
.class_card .classTitle {
	font-weight: bold;
	font-size: larger;
	color: white;
	background-color: rgba(0, 0, 0, .5);
	padding: 8px 12px;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	box-sizing: border-box;
}

.class_card .lesson_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px;
	gap: 12px;
}
.class_card .lesson_item:hover {
	background-color: tomato;
}
.class_card .lesson_icon {
	height: 50px;
}
.class_card a {
	font-size: larger;
	color: green;
	cursor: pointer;
	text-decoration: none;
}